import React, { useEffect, useState } from "react";
import {
  Layout,
  Table,
  Tabs,
  Card,
  Statistic,
  Row,
  Col,
  Popconfirm,
  message,
  QRCode,
  Input,
  Space,
  Image,
} from "antd";
import {
  UsergroupAddOutlined,
  QrcodeOutlined as QRIcon,
  FileTextOutlined as ContentIcon,
} from "@ant-design/icons";
import "./adminDashboard.css";
import axios from "axios";
import { BaseUrl } from "../shared/BaseUrl";
import { DeleteTwoTone, RemoveRedEyeTwoTone } from "@mui/icons-material";
const { Content } = Layout;
const { TabPane } = Tabs;

export const AdminDashboard = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [activeTab, setActiveTab] = useState("dashboard");
  const token = localStorage.getItem("token");

  const [users, setUsers] = useState([
    { key: "1", name: "John Doe", email: "john@example.com" },
    { key: "2", name: "Jane Smith", email: "jane@example.com" },
  ]);
  const [qrContent, setQrContent] = useState([
    { key: "1", title: "Sample QR Content 1", type: "URL" },
    { key: "2", title: "Sample QR Content 2", type: "Text" },
  ]);

  const [allContent, setAllContent] = useState([]);

  const [statistics, setStatistics] = useState({
    usersCount: 0,
    qrCodesCount: 0,
    contentCount: 0,
  });

  const [searchText, setSearchText] = useState("");

  // Filtered data based on search query
  const filteredContents = allContent?.data?.contents?.filter(
    (item) =>
      item.contentType?.toLowerCase().includes(searchText?.toLowerCase()) ||
      item.contentUrl?.toLowerCase().includes(searchText?.toLowerCase())
  );

  const filteredHistoricalContents =
    allContent?.data?.historicalContents?.filter(
      (item) =>
        item.contentType?.toLowerCase().includes(searchText?.toLowerCase()) ||
        item.contentUrl?.toLowerCase().includes(searchText?.toLowerCase())
    );
  const toggleCollapse = () => setCollapsed(!collapsed);

  //   const deleteUser = (key) => {
  //     setUsers(users.filter((user) => user.key !== key));
  //     message.success("User deleted successfully!");
  //   };

  //   const deleteQrContent = (key) => {
  //     setQrContent(qrContent.filter((item) => item.key !== key));
  //     message.success("QR Content deleted successfully!");
  //   };

  const userColumns = [
    { title: "Name", dataIndex: "username", key: "id" },
    { title: "Email", dataIndex: "email", key: "id" },
    {
      title: "QR",
      dataIndex: "qrCodes",
      key: "qrCodes",
      render: (qrCodes) => (
        <>
          {qrCodes && qrCodes.length > 0
            ? qrCodes.map((qr, index) => (
                // <Image
                //   key={qr._id}
                //   src={qr.url}
                //   alt={`QR Code ${qr._id}`}
                //   width={50}
                //   style={{ marginRight: "10px" }}
                //   preview={{
                //     src: qr.url,
                //   }}
                // />
                <QRCode className="qr-code" value={qr.text} size={100} />
              ))
            : "No QR Code"}
        </>
      ),
    },
    {
      title: "Remove",
      key: "action",
      render: (text, record) => (
        <Popconfirm
          title="Are you sure you want to delete this user?"
          onConfirm={() => deleteUser(record._id)}
          okText="Yes"
          cancelText="No"
          okButtonProps={{
            style: { width: "30%" },
            danger: true,
          }}
          cancelButtonProps={{
            style: { width: "30%" },
          }}
          // okButtonProps={{ danger: true }}
        >
          <DeleteTwoTone color="warning" />
        </Popconfirm>
      ),
    },
  ];

  const qrContentColumns = [
    {
      title: "Link",
      dataIndex: "text",
      key: "id",
      render: (text, record) => (
        <a href={record.url} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
    },
    {
      title: "View",
      dataIndex: "url",
      key: "id",
      render: (text, record) => (
        <Space size="middle">
          <Image
            key={record._id}
            src={record.url}
            alt={`N/A`}
            width={50}
            style={{ marginRight: "10px" }}
            preview={{
              src: record.url,
            }}
          />
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Popconfirm
          title="Are you sure you want to delete this content?"
          onConfirm={() => deleteQrContent(record._id)}
          okText="Yes"
          cancelText="No"
          okButtonProps={{
            style: { width: "30%" },
            danger: true,
          }}
          cancelButtonProps={{
            style: { width: "30%" },
          }}
        >
          <DeleteTwoTone color="warning" />
        </Popconfirm>
      ),
    },
  ];

  const qrAllContentColumns = [
    { title: "Content Type", dataIndex: "contentType", key: "contentType" },
    {
      title: "View",
      dataIndex: "contentUrl",
      key: "contentUrl",
      render: (text, record) => (
        <Space size="middle">
          <Image
            key={record._id}
            src={record.url}
            alt={`N/A`}
            width={50}
            style={{ marginRight: "10px" }}
            preview={{
              src: record.url,
            }}
          />
          {/* <Button type="primary">View</Button> */}
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Popconfirm
          title="Are you sure you want to delete this content?"
          onConfirm={() => deleteQrContent(record._id)}
          okText="Yes"
          cancelText="No"
          okButtonProps={{
            style: { width: "30%" },
            danger: true,
          }}
          cancelButtonProps={{
            style: { width: "30%" },
          }}
        >
          <DeleteTwoTone color="warning" />
        </Popconfirm>
      ),
    },
  ];

  // Example: Fetch users from backend
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/api/admin/users`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUsers(response.data);
      } catch (error) {
        message.error("Failed to fetch users");
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchAllContent = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/api/admin/allcontents`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log({ response });
        setAllContent(response.data);
      } catch (error) {
        message.error("Failed to fetch users");
      }
    };
    fetchAllContent();
  }, []);

  // useEffect(() => {
  //   const fetchQrContent = async () => {
  //     try {
  //       const response = await axios.get(`${BaseUrl}/api/admin/qr-content`, {
  //         headers: { Authorization: `Bearer ${token}` },
  //       });
  //       setQrContent(response.data);
  //     } catch (error) {
  //       message.error("Failed to fetch QR content");
  //     }
  //   };

  //   fetchQrContent();
  // }, []);

  const deleteUser = async (id) => {
    try {
      const res = await axios.delete(`${BaseUrl}/api/admin/users?id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log("res is ", res);

      setUsers(users.filter((user) => user._id !== id));
      message.success("User deleted successfully!");
    } catch (error) {
      message.error("Failed to delete user");
    }
  };

  const deleteQrContent = async (key) => {
    try {
      await axios.delete(`${BaseUrl}/api/admin/qrcodes?id=${key}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setQrContent(qrContent.filter((item) => item.key !== key));
      message.success("QR content deleted successfully!");
    } catch (error) {
      message.error("Failed to delete QR content");
    }
  };

  useEffect(() => {
    const fetchQRCodes = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/api/admin/qrcodes`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setQrContent(response.data);
      } catch (error) {
        message.error("Failed to fetch QR codes");
      }
    };

    fetchQRCodes();
  }, []);

  useEffect(() => {
    const fetchAppStatistics = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/api/admin/statistics`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        // Assuming the response contains an object with keys like usersCount, qrCodesCount, contentCount
        const { userCount, qrCodeCount, contentCount } = response.data;
        console.log("stat res is", response.data);

        setStatistics({
          usersCount: userCount ? userCount : 0,
          qrCodesCount: qrCodeCount ? qrCodeCount : 0,
          contentCount: contentCount ? contentCount : 0,
        });
        console.log("statsss are", statistics);
      } catch (error) {
        message.error("Failed to fetch app statistics");
      }
    };

    fetchAppStatistics();
  }, []);

  const searchUsers = async (query) => {
    try {
      const response = await axios.get(
        `${BaseUrl}/api/admin/users/search?query=${query}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setUsers(response.data);
    } catch (error) {
      message.error("Failed to search users");
    }
  };

  const handleSearch = (value) => {
    searchUsers(value);
  };
  const handleContentSearch = (value) => {
    setSearchText(value);
  };

  const updateUser = async (id, updatedUser) => {
    try {
      await axios.put(`${BaseUrl}/api/admin/users/${id}`, updatedUser, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUsers((prevUsers) =>
        prevUsers.map((user) => (user.id === id ? updatedUser : user))
      );
      message.success("User updated successfully!");
    } catch (error) {
      message.error("Failed to update user");
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case "dashboard":
        return (
          <>
            <Row gutter={16} className="dashboard-cards">
              <Col span={8} style={{ marginBottom: "1rem" }}>
                <Card
                  bordered={false}
                  className="dashboard-card"
                  style={{ cursor: "pointer" }}
                >
                  <Statistic
                    title="Total Users"
                    value={statistics.usersCount}
                    prefix={<UsergroupAddOutlined />}
                    valueStyle={{ color: "#3f8600" }}
                  />
                </Card>
              </Col>
              <Col span={8} style={{ marginBottom: "1rem" }}>
                <Card
                  bordered={false}
                  className="dashboard-card"
                  style={{ cursor: "pointer" }}
                >
                  <Statistic
                    title="Total QR Codes"
                    value={statistics.qrCodesCount}
                    prefix={<QRIcon />}
                    valueStyle={{ color: "#1890ff" }}
                  />
                </Card>
              </Col>
              <Col span={8} style={{ marginBottom: "3rem" }}>
                <Card
                  bordered={false}
                  className="dashboard-card"
                  style={{ cursor: "pointer" }}
                >
                  <Statistic
                    title="Total Content"
                    value={statistics.contentCount} // Replace with dynamic value if needed
                    prefix={<ContentIcon />}
                    valueStyle={{ color: "#cf1322" }}
                  />
                </Card>
              </Col>
            </Row>
          </>
        );
      case "users":
        return (
          <>
            <Input.Search
              placeholder="Search users by name or email"
              enterButton
              onSearch={handleSearch}
              style={{ marginBottom: "16px", maxWidth: "300px" }}
            />
            <Table
              columns={userColumns}
              dataSource={users}
              style={{ overflow: "scroll", backgroundColor: "white" }}
            />
            ;
          </>
        );
      case "qrcontent":
        return (
          <div>
            <Input.Search
              placeholder="Search Contents"
              enterButton
              onSearch={handleContentSearch}
              style={{ marginBottom: "16px", maxWidth: "300px" }}
            />
            <h1>Content</h1>
            <Table
              columns={qrAllContentColumns}
              dataSource={filteredContents}
              style={{ overflow: "scroll", backgroundColor: "white" }}
            />
            <h1>Historical Content</h1>
            <Table
              columns={qrAllContentColumns}
              dataSource={filteredHistoricalContents}
              style={{ overflow: "scroll", backgroundColor: "white" }}
            />
          </div>
        );
      case "qr":
        return (
          <div>
            <Input.Search
              placeholder="Search QR"
              enterButton
              onSearch={handleContentSearch}
              style={{ marginBottom: "16px", maxWidth: "300px" }}
            />
            <Table
              columns={qrContentColumns}
              dataSource={qrContent.data}
              style={{ overflow: "scroll", backgroundColor: "white" }}
            />
            ;
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout className="site-layout">
        <Content style={{ margin: "16px" }}>
          <Tabs activeKey={activeTab} onChange={setActiveTab}>
            <TabPane tab="Dashboard" key="dashboard">
              {renderContent()}
            </TabPane>
            <TabPane tab="Users" key="users">
              {renderContent()}
            </TabPane>
            <TabPane tab="QR Content" key="qrcontent">
              {renderContent()}
            </TabPane>
            <TabPane tab="QR" key="qr">
              {renderContent()}
            </TabPane>
          </Tabs>
        </Content>
      </Layout>
    </Layout>
  );
};
