import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Table,
  Layout,
  Spin,
  Checkbox,
  Modal,
  Space,
  Button,
  Select,
} from "antd";
import axios from "axios";
import { BaseUrl } from "../shared/BaseUrl";
import QRCode from "qrcode.react";
import { toast } from "react-toastify";
import { DeleteTwoTone, RemoveRedEyeTwoTone } from "@mui/icons-material";

const { Content } = Layout;
const { Option } = Select;

export const Dashboard = () => {
  const [qrCodeURL, setQRCodeURL] = useState("");
  const [qrId, setQrId] = useState();
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentContentId, setCurrentContentId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [contentToDelete, setContentToDelete] = useState(null);
  const [selectedType, setSelectedType] = useState("audio"); // State for selected content type
  const [selectedCategory, setSelectedCategory] = useState("All"); // State for selected category
  const token = localStorage.getItem("token");

  const qrCodeRef = useRef();

  useLayoutEffect(() => {
    const fetchQr = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/api/qrcode/user/qr`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
        });
        setQRCodeURL(response.data.text);
        setQrId(response.data._id);
      } catch (error) {
        console.error("Login error:", error.message);
      }
    };
    fetchQr();
  }, [token]);

  useEffect(() => {
    if (qrId) {
      const fetchContents = async () => {
        try {
          const response = await axios.get(
            `${BaseUrl}/api/content/contents/${qrId}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setContents(response?.data?.contents);
          setCurrentContentId(response?.data?.currentContent?._id);
          setLoading(false);
        } catch (error) {
          console.error("Fetch content error:", error.message);
          setLoading(false);
        }
      };
      fetchContents();
    }
  }, [qrId, token]);

  const handleContentTypeChange = (value) => {
    setSelectedType(value);
  };

  const handleCheckboxChange = async (checked, record) => {
    if (checked) {
      setIsModalOpen(true);
      setCurrentContentId(record._id);
    } else {
      setCurrentContentId(null);
    }
  };

  const handleOk = async () => {
    const response = await axios.post(
      `${BaseUrl}/api/content/set/${currentContentId}`,
      { qrId: qrId },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    toast.success(response.data.message, {
      position: "top-right",
      autoClose: 3000,
    });
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDelete = async (contentId) => {
    try {
      const response = await axios.delete(
        `${BaseUrl}/api/content/delete/${contentId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 3000,
      });
      setContents(contents.filter((item) => item._id !== contentId));
    } catch (error) {
      console.error("Delete content error:", error.message);
    }
  };

  const handleDeleteOk = () => {
    if (contentToDelete) {
      handleDelete(contentToDelete);
      setIsDeleteModalOpen(false);
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const columns = [
    {
      title: "ID",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Text",
      dataIndex: "text",
      key: "text",
      render: (text) => (text ? text : <b>N/A</b>),
    },
    {
      title: "Label",
      dataIndex: "label",
      key: "label",
      render: (label) => (label ? <b> {label}</b> : <b>N/A</b>),
    },
    {
      title: "Upload Time",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "View",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <RemoveRedEyeTwoTone
            onClick={() => {
              window.open(record.contentUrl, "_blank");
            }}
            color="success"
          />
        </Space>
      ),
    },
    {
      title: "Delete",
      key: "delete",
      render: (text, record) => (
        <Space size="middle">
          <DeleteTwoTone
            color="warning"
            danger
            onClick={() => {
              setIsDeleteModalOpen(true);
              setContentToDelete(record._id);
            }}
          />
        </Space>
      ),
    },
    {
      title: "Select",
      key: "checkbox",
      render: (text, record) => (
        <Checkbox
          checked={record._id === currentContentId}
          onChange={(e) => handleCheckboxChange(e.target.checked, record)}
        />
      ),
    },
  ];

  const filteredContents =
    selectedCategory === "All"
      ? contents // If "All" is selected, use the entire contents array
      : contents.filter(
          (content) =>
            content.contentType === selectedType &&
            content.category === selectedCategory
        );
  const handleDownloadQRCode = () => {
    const canvas = qrCodeRef.current.querySelector("canvas");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qr_code.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Layout style={{ padding: "0 24px 24px", background: "transparent" }}>
      {loading ? (
        <Spin
          tip="Loading..."
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        />
      ) : (
        <>
          <div
            style={{
              marginTop: "2rem",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              ref={qrCodeRef}
              style={{
                background: "#fff",
                padding: "24px",
                display: "inline-block",
              }}
            >
              <QRCode value={qrCodeURL} size={200} />
            </div>
            <Button
              style={{ marginTop: "12px" }}
              type="primary"
              onClick={handleDownloadQRCode}
            >
              Download QR Code
            </Button>
          </div>

          <Content style={{ marginTop: "24px" }}>
            <Select
              defaultValue="audio"
              style={{
                width: 200,
                marginTop: "24px",
                marginRight: "16px",
              }}
              onChange={handleContentTypeChange}
            >
              <Option value="audio">Audio</Option>
              <Option value="video">Video</Option>
              <Option value="image">Image</Option>
              <Option value="text">Text</Option>
            </Select>

            {/* New category dropdown */}
            <Select
              defaultValue="All"
              style={{
                width: 200,
                marginTop: "24px",
                marginLeft: "16px", // for some spacing
              }}
              onChange={(value) => setSelectedCategory(value)}
            >
              <Option value="All">All</Option>
              <Option value="Work">Work</Option>
              <Option value="Party">Party</Option>
              <Option value="Other">Other</Option>
              <Option value="Family">Family</Option>
              <Option value="Personal">Personal</Option>
            </Select>

            <Table
              style={{ marginTop: "2rem" }}
              columns={columns}
              dataSource={filteredContents}
              rowKey={(record) => record._id}
            />
          </Content>

          {/* Modal to confirm the selection */}
          <Modal
            title="Confirm Selection"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Set Content"
          >
            <p>
              Are you sure you want to select this content as the default for
              your QR code?
            </p>
          </Modal>

          {/* Modal to confirm deletion */}
          <Modal
            title="Confirm Deletion"
            open={isDeleteModalOpen}
            onOk={handleDeleteOk}
            onCancel={handleDeleteCancel}
            okText="Delete"
            style={{ height: "200px" }}
          >
            <p>Are you sure you want to delete this content?</p>
          </Modal>
        </>
      )}
    </Layout>
  );
};
